import { BabylonVisualiser } from "components/babylonVisualiser";
import { HtmlVisualiser } from "components/htmlVisualiser";
import { PixiVisualiser } from "components/pixiVisualiser";
import { Visualiser } from "components/visualiser";
import { useStore } from "context";
import { observer } from "mobx-react-lite";
import { Visualizers } from "types/common";

export const Visualizator = observer(() => {
    const store = useStore();
    console.log("store.settings.visualizerType", store.settings.visualizerType);
    switch (store.settings.visualizerType) {
        case Visualizers.Canvas:
            return <Visualiser />;
        case Visualizers.HTML:
            return <HtmlVisualiser />;
        case Visualizers.Babylon:
            return <BabylonVisualiser />;
        case Visualizers.Pixi:
            return <PixiVisualiser />;
    }
});
