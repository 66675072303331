import { useCallback, useEffect, useRef } from "react";


export const useFps = (callback: (v: number) => void, frequency = 1000) => {
    const timeRef = useRef<number>(performance.now());
    const frames = useRef<number>(0);
    const rafRef = useRef<number>(0);

    const update = useCallback(() => {
        if (performance.now() - timeRef.current >= frequency) {
            callback(frames.current * 1000 / frequency);
            frames.current = 0;
            timeRef.current = performance.now();
        } else {
            frames.current += 1;
        }
        rafRef.current = requestAnimationFrame(update);
    }, [callback, frequency]);

    useEffect(() => {
        rafRef.current = requestAnimationFrame(update);

        return () => {
            cancelAnimationFrame(rafRef.current);
        };
    }, [update]);
};
