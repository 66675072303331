import React from 'react';

import { Col, Layout, Row } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { AudioPicker } from "components/audioPicker";
import { AudioPlayer } from "components/audioPlayer";
import { VisualizerSwitcher, ParticleCount } from "components/config";
import { FpsMeter } from "components/FPSMeter";
import { Visualizator } from "components/visualizator";

import "./App.css";
import cn from "./App.module.css";

function App() {
    return (
        <Layout className={cn.app}>
            <Header>
                <Row align="middle" justify="space-around" >
                    <FpsMeter />
                    <Col span={8}>
                        <VisualizerSwitcher />
                    </Col>
                    <ParticleCount />
                </Row>
            </Header>
            <Content>
                <Visualizator />
            </Content>
            <Footer>
               <Row align="middle" justify="space-around" wrap={false}>
                   <AudioPicker />
               </Row>
                <Row align="middle" justify="space-around" wrap={false}>
                    <AudioPlayer />
                </Row>
            </Footer>
        </Layout>
  );
}

export default App;
