import { makeAutoObservable } from "mobx";

export class Visualizer {
    analyser?: AnalyserNode;
    audio?: string;
    countParticles: number = 32;

    constructor() {
        makeAutoObservable(this);
    }

    setAudio(url: string) {
        this.audio = url;
    }

    setAnalyser(analyser: AnalyserNode) {
        this.analyser = analyser;
    }

    updateCountParticles(value: number) {
        const newVal = 2 ** Math.round(Math.log2(value));

        console.log("newVal", newVal, Math.log2(value));
        if (newVal <= 32) {
            this.countParticles = 32;
        } else if (newVal > 32768) {
            this.countParticles = 32768;
        } else {
            this.countParticles = newVal;
        }
    }

}

