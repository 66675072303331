import React from 'react';

import { Store } from './state';

const storeContext = React.createContext<Store | null>(null);

export const StoreProvider: React.FC = ({ children }) => {
    return (
        <storeContext.Provider value={new Store()}>
            {children}
        </storeContext.Provider>
    );
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('useStore must be used within a StoreProvider.');
    }
    return store;
};
