import { makeAutoObservable, autorun } from "mobx";

import { Settings } from "./Settings";
import { Visualizer } from "./Visualizer";

export class Store {
    visualizer: Visualizer
    settings: Settings

    constructor() {
        makeAutoObservable(this);
        this.visualizer = new Visualizer();
        this.settings = new Settings();

        autorun(() => {
            console.log("Auto Run");
        });
    }
}

