import React from "react";

import { Radio, RadioChangeEvent } from 'antd';
import { useStore } from "context";
import { observer } from "mobx-react-lite";
import { Visualizers } from "types/common";

export const VisualizerSwitcher = observer(() => {
    const store = useStore();

    const handleChange = React.useCallback((e: RadioChangeEvent) => {
        store.settings.changeVisualizerType(e.target.value);
    }, [store.settings]);

    console.log("VisualizerSwitcher Rerender");
    return (
        <Radio.Group defaultValue={store.settings.visualizerType} buttonStyle="solid" onChange={handleChange}>
            <Radio.Button value={Visualizers.HTML}>{Visualizers.HTML}</Radio.Button>
            <Radio.Button value={Visualizers.Canvas}>{Visualizers.Canvas}</Radio.Button>
            <Radio.Button value={Visualizers.Babylon}>{Visualizers.Babylon}</Radio.Button>
            <Radio.Button value={Visualizers.Pixi}>{Visualizers.Pixi}</Radio.Button>
        </Radio.Group>
    );
});
