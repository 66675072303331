import React from "react";

import { observer } from "mobx-react-lite";

import { useStore } from "../../context";
import cn from "./Visualiser.module.css";

export const Visualiser = observer(() => {
    const dataRef = React.useRef<Uint8Array>(new Uint8Array());
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const requestRef = React.useRef<null | number>(null);
    const ctxRef = React.useRef<CanvasRenderingContext2D | null>(null);
    const store = useStore();

    const updCanvas = React.useCallback(() => {
        if (!canvasRef.current) {
            return;
        }
        const q = canvasRef.current.height / 256;
        if (store.visualizer.analyser) {
            store.visualizer.analyser.getByteFrequencyData(dataRef.current!);
            let space = canvasRef.current!.width / dataRef.current.length;

            ctxRef.current!.fillStyle = "#fad4d4";
            ctxRef.current!.fillRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
            dataRef.current!.forEach((value,i) => {
                ctxRef.current!.beginPath();
                ctxRef.current!.strokeStyle = `rgb(${value - 1},20, ${255 - value})`;
                ctxRef.current!.moveTo(space*i,canvasRef.current!.height); //x,y
                ctxRef.current!.lineTo(space*i,canvasRef.current!.height-value * q); //x,y
                ctxRef.current!.stroke();
            });

            requestRef.current = requestAnimationFrame(updCanvas);
        }
    }, [store.visualizer.analyser]);


    React.useEffect(() => {
        console.log("Set analyser");
        if (store.visualizer.analyser) {
            dataRef.current = new Uint8Array(store.visualizer.analyser.frequencyBinCount);
        }

    }, [store.visualizer.analyser]);

    React.useEffect(() => {
        if (store.visualizer.analyser) {
            dataRef.current = new Uint8Array(store.visualizer.countParticles / 2);
        }

    }, [store.visualizer.countParticles, store.visualizer.analyser]);

    React.useLayoutEffect(() => {
        if (canvasRef.current) {
            ctxRef.current = canvasRef.current.getContext("2d")!;
            ctxRef.current.fillStyle = "#cccccc";
            ctxRef.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            canvasRef.current.width = devicePixelRatio * canvasRef.current?.clientWidth;
            canvasRef.current.height = devicePixelRatio * canvasRef.current?.clientHeight;
        }
    }, []);

    React.useEffect(() => {
        updCanvas();
        return () => {
            if (requestRef.current) {
                window.cancelAnimationFrame(requestRef.current);
            }
        };

    }, [updCanvas]);

    return (
        <div className={cn.container}>
            <canvas className={cn.canvas} ref={canvasRef} />
        </div>

    );
});
