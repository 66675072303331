import React from "react";

import { useStore } from "context";
import { observer } from "mobx-react-lite";

import cn from "./HtmlVisualiser.module.css";

export const HtmlVisualiser = observer(() => {
    const requestRef = React.useRef<null | number>(null);
    const elements = React.useRef<HTMLDivElement[]>([]);
    const store = useStore();
    const data = React.useMemo(() => {
        if (store.visualizer.analyser) {
            elements.current = [];
            return new Uint8Array(store.visualizer.countParticles / 2);
        }

        return null;

    }, [store.visualizer.analyser, store.visualizer.countParticles]);
    const updCanvas = React.useCallback(() => {
        if (store.visualizer.analyser && data) {
            store.visualizer.analyser.getByteFrequencyData(data);
            elements.current.forEach((node, i) => {
                const val = data[i];
                if (val !== undefined) {
                    node.style.transform = `translateY(${100 - (val * 100 / 255)}%)`;
                }
            });

            requestRef.current = requestAnimationFrame(updCanvas);
        }
    }, [store.visualizer.analyser, data]);

    React.useEffect(() => {
        updCanvas();

        return () => {
            if (requestRef.current) {
                window.cancelAnimationFrame(requestRef.current);
            }
        };
    }, [updCanvas]);

    const setRef = (element: HTMLDivElement) => {
        if (element) {
            elements.current.push(element);
        }
    };

    return (
        <div className={cn.container}>
            {
                data ? (
                    <div className={cn.htmlVisualiser}>
                        {Array.from(data).map((it, i) => <span className={cn.line} ref={setRef} key={Math.random()} />)}
                    </div>
                ) : null
            }
        </div>
    );
});
