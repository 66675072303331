import React from "react";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useStore } from "context";


export const AudioPicker = () => {
    const store = useStore();
    const handleChange = React.useCallback((file) => {
        console.log("file", file);
        const url = URL.createObjectURL(file);
        if (url) {
            store.visualizer.setAudio(url);
        }
    }, [store]);

    React.useEffect(() => {

    }, []);
    return (
        <Upload beforeUpload={handleChange} accept="mp3" maxCount={1}>
            <Button
                icon={<UploadOutlined />}
            >
                Click to Upload
            </Button>
        </Upload>

        // <input onChange={handleChange} type="file" ref={pickerRef}/>
    );
};
