import { makeAutoObservable } from "mobx";
import { Visualizers } from "types/common";

export class Settings {
    visualizerType: Visualizers = Visualizers.Canvas;

    constructor() {
        makeAutoObservable(this);
    }

    changeVisualizerType(type: Visualizers) {
        this.visualizerType = type;
    }
}

