import React, { useRef } from "react";

import { observer } from "mobx-react-lite";

import { useStore } from "../../context";
import cn from "./ParticleCount.module.css";
export const ParticleCount = observer(() => {
    const store = useStore();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleBlur = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        store.visualizer.updateCountParticles(Number(e.target.value));
    }, [store.visualizer]);
    console.log("Config Rerender");
    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = String(store.visualizer.countParticles);
        }
    }, [store.visualizer.countParticles]);


    return (
        <input
            className={cn.particleCount}
            ref={inputRef}
            type="text"
            onBlur={handleBlur}
            defaultValue={store.visualizer.countParticles}
            pattern="[0-9]*"
        />
    );
});
