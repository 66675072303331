import React from "react";

import { observer } from "mobx-react-lite";

import { useStore } from "../../context";

const mp3 = require("./relax.mp3");

export const AudioPlayer = observer(() => {
    const audioRef = React.useRef<HTMLMediaElement | null>(null);
    const store = useStore()!;
    React.useEffect(() => {
        if (audioRef.current) {
            const audioCtx = new AudioContext();
            const analyser = audioCtx.createAnalyser();
            analyser.fftSize = store.visualizer.countParticles;
            const source = audioCtx.createMediaElementSource(audioRef.current);

            source.connect(analyser);
            source.connect(audioCtx.destination);

            store.visualizer.setAnalyser(analyser);

            audioRef.current.onplay = () => {
                audioCtx.resume();
            };

            audioRef.current.onpause = () => {
                audioCtx.suspend();
            };
        }

    }, [store.visualizer]);

    React.useEffect(() => {
        if (store.visualizer.analyser) {
            store.visualizer.analyser.fftSize = store.visualizer.countParticles;
        }
    }, [store.visualizer.analyser, store.visualizer.countParticles]);

    React.useEffect(() => {
        if (store.visualizer.audio) {
            audioRef.current!.src = store.visualizer.audio;
        }
    }, [store.visualizer.audio]);
    console.log("PLAYER  RERENDER");
    return (
        <audio controls ref={audioRef} src={mp3.default} />
    );
});
