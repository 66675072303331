import React from "react";

import { Application, Graphics, Ticker, Text } from 'pixi.js';
import { useStore } from "context";
import { observer } from "mobx-react-lite";

export const PixiVisualiser = observer(() => {
    const dataRef = React.useRef<Uint8Array>(new Uint8Array());
    const ticker = React.useRef<Ticker | null>(null);
    const [canvas, setCanvas] = React.useState<HTMLCanvasElement | null>(null);
    const [app, setApp] = React.useState<Application | null>(null);

    const store = useStore();
    console.log("PixiVisualiser Render");
    const setCanvasElement = React.useCallback((element: HTMLCanvasElement) => {
        setCanvas(element);
    }, []);
    const draw = () => {
        if (store.visualizer.analyser && app) {
            store.visualizer.analyser.getByteFrequencyData(dataRef.current);
            dataRef.current!.forEach((value,i) => {
                app.stage.children[i]!.y = app.view.height - value * app.view.height / 255;
            });
        }
    };

    React.useEffect(() => {
        return () => {
            console.log("UNMOUNT PIXI VISUALISER");
            if (app) {
                app.ticker.remove(draw);
                app.destroy(true);
            }
        };
    }, []);

    React.useEffect(() => {
        console.log("Set analyser");
        if (store.visualizer.analyser) {
            dataRef.current = new Uint8Array(store.visualizer.analyser.frequencyBinCount);
        }

    }, [store.visualizer.analyser]);

    React.useEffect(() => {
        if (store.visualizer.analyser && app) {
            dataRef.current = new Uint8Array(store.visualizer.countParticles / 2);
            console.log("app", app);
            app.stage.removeChildren();

            const w = app.view.width / dataRef.current.length;
            const obj = new Graphics();
            obj.beginFill(0xff0000);
            obj.drawRect(0, 0, w, app.view.height);
            app.stage.addChild(obj);
            for (let i = 1; i < dataRef.current.length; i++) {
                const newo = new Graphics(obj.geometry);
                newo.position.x += i * w;
                app.stage.addChild(newo);
            }
            ticker.current = app.ticker.add(draw);
        }

    }, [store.visualizer.countParticles, store.visualizer.analyser, app]);

    React.useEffect(() => {
        if (canvas) {
            const app = new Application({ view: canvas });
            setApp(app);
        }
    }, [canvas]);

    return <canvas ref={setCanvasElement} style={{ width: "100%", height: "100%" }} />;
});
