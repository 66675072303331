import React, { memo, useState } from "react";

import { Row } from "antd";

import cn from "./FPSMeter.module.css";
import { useFps } from "./useFps";

export const FpsMeter = memo(() => {
    const [fps, setFps] = useState(0);
    useFps(setFps);
    const g = Math.floor(fps * 255 / 60);
    const styles = {
        background: `rgb(${255 - g}, ${g}, 0)`,
    };
    return (
        <Row align="middle" justify="start" >
            <span className={cn.fpsMeterTitle} >FPS: </span>
            <div className={cn.fpsMeterContainer} style={styles}>
                <span className={cn.fpsMeter}>{fps}</span>
            </div>
        </Row>


    );
});
