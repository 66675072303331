import React from "react";

import { LinesBuilder, Vector3 } from "@babylonjs/core";
import { useStore } from "context";
import { observer } from "mobx-react-lite";

import { use3dScene } from "./useScene";

export const BabylonVisualiser = observer(() => {
    const dataRef = React.useRef<Uint8Array>(new Uint8Array());
    const [canvas, setCanvas] = React.useState<HTMLCanvasElement | null>(null);
    const scene = use3dScene(canvas, {});
    const store = useStore();
    console.log("BabylonVisualiser Render", scene);
    const setCanvasElement = React.useCallback((element: HTMLCanvasElement) => {
        setCanvas(element);
    }, []);
    const draw = React.useCallback(() => {
        if (store.visualizer.analyser && scene) {
            store.visualizer.analyser.getByteFrequencyData(dataRef.current);
            dataRef.current!.forEach((value,i) => {
                const mesh = scene.meshes[i];
                if (mesh) {
                    mesh.scaling.y = value / 255;
                }
            });
        }

    }, [scene, store.visualizer.analyser]);

    React.useEffect(() => {
        console.log("Set analyser");
        if (store.visualizer.analyser) {
            dataRef.current = new Uint8Array(store.visualizer.analyser.frequencyBinCount);
        }

    }, [store.visualizer.analyser]);

    React.useEffect(() => {
        if (store.visualizer.analyser && scene) {
            console.log("[BabylonVisualiser] SETUP PARTICLES");
            dataRef.current = new Uint8Array(store.visualizer.countParticles / 2);
            for (const mesh of scene.meshes) {
                mesh.dispose();
            }
            const newMesh = LinesBuilder.CreateLines("box", {
                points: [new Vector3(0, 0, 0), new Vector3(0, 1, 0)]
            });
            newMesh.setPivotPoint(new Vector3(0, 1, 0));
            for (let i = 1; i < store.visualizer.countParticles / 2; i++) {
                const newMesh1 = newMesh.createInstance(i + newMesh.name);
                newMesh1.rotation.x += 4 * i * Math.PI / store.visualizer.countParticles;
            }
        }

    }, [store.visualizer.countParticles, store.visualizer.analyser, scene]);

    React.useEffect(() => {
        if (scene) {
            scene.registerBeforeRender(draw);
        }
    }, [scene, draw]);

    return <canvas ref={setCanvasElement} style={{ width: "100%", height: "100%" }} />;
});
