import React from "react";

import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Engine } from "@babylonjs/core/Engines/engine";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { Color3, Color4, Vector3 } from "@babylonjs/core/Maths/math";
import { Scene } from "@babylonjs/core/scene";

import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";


interface SceneOptions {
    cameraRadius?: number;
    minZ?: number;
}

export function use3dScene(
    canvas: HTMLCanvasElement | null,
    {
        cameraRadius = 2.5,
        minZ = 1,
    }: SceneOptions,
): Scene | null {
    const [scene, setScene] = React.useState<Scene | null>(null);
    React.useEffect(() => {
        if (canvas !== null) {
            console.log("[BabylonVisualiser] Start setup 3d scene...");
            const engine = new Engine(canvas, true);
            const newScene = new Scene(engine);
            newScene.clearColor = Color4.FromColor3(Color3.Black());
            setScene(newScene);
            new HemisphericLight("light", new Vector3(0, 1, 0), newScene);

            const camera: ArcRotateCamera = new ArcRotateCamera("camera",
                0, Math.PI / 2, cameraRadius, new Vector3(0, 1, 0), newScene);
            camera.minZ = minZ;
            camera.attachControl(canvas, true);

            engine.runRenderLoop(() => newScene.render());

            const keydownCallback = (ev: KeyboardEvent) => {
                // Shift+Q
                console.log("KEY Q");
                if (ev.shiftKey && ev.code === "KeyQ") {
                    if (newScene.debugLayer.isVisible()) {
                        newScene.debugLayer.hide();
                    } else {
                        newScene.debugLayer.show({ overlay: true });
                    }
                }
            };
            console.log("[BabylonVisualiser] Add HotKey");
            window.addEventListener("keydown", keydownCallback);


            return () => {
                window.removeEventListener("keydown", keydownCallback);
                engine.dispose();
            };
        }
        return () => {
            console.log("DISPOSE2");
        };
    }, [canvas]);

    return scene;
}
